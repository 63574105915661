<template>
	<div id="Back_color" v-if="computedValue === `ko`">
		<header>
			<h1>FOUR-CHAINS 개인정보 처리 방침</h1>
		</header>
		<div id="Container_box">
			<div id="container">
				<section class="content1">
					<h5>1. 개인정보의 수집 범위</h5>
					<p>
						㈜포체인스(이하 회사 또는 포체인스)는 회원가입 이후 서비스를 이용할
						수 있습니다. 또한 개인회원의 회원가입의 경우 서비스에 관한 정보
						제공만 이용이 가능하며, 기업회원으로 전환이 필요한 부분은
						㈜포체인스가 요구하는 정보를 제공 해주시면 서비스 이용이 가능합니다.
					</p>
					<ul>
						<li>회원가입 시 수집하는 개인정보의 범위</li>
						<p>
							– 개인 회원 기준 필수 항목 : ID, 비밀번호, 전화번호(핸드폰 또는
							자택번호 택일), 이메일
						</p>
						<p>– 개인 회원 기준 선택 항목 : 이름, 생년월일, 회사, 주소</p>
					</ul>

					<h5>2.개인정보의 수집목적 및 이용목적</h5>
					<ul>
						<li>
							㈜포체인스는 개인정보를 다음과 같은 목적으로 수집하고 있습니다.
						</li>
						<p>
							– 성명, 아이디, 비밀번호 : 회원 가입자 본인 식별 절차에
							이용(실명확인)
						</p>
						<p>
							– 이메일주소, 연락처(핸드폰 또는자택) : 공지 및 안내사항, 고객대응
							및 기타 서비스 개선을 위한 의사소통 경로
						</p>
						<li>
							㈜포체인스는 상기 수집하는 개인정보를 제외하고 회원의 인권침해
							소지가 있는 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지,
							정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 수집하지
							않습니다.
						</li>
					</ul>

					<h5>3. 개인정보의 보유기간 및 이용기간</h5>
					<p>
						회원의 개인정보는 다음과 같이 개인정보의 수집목적 또는 제공받은
						목적이 달성되면 파기됩니다. 단, 상법 등 관련법령의 규정에 의하여
						다음과 같이 거래 관련 권리 의무 관계의 확인 등을 이유로 일정기간
						보유하여야 할 필요가 있을 경우에는 일정기간 보유합니다.
					</p>
					<p>
						– 회원가입정보의 경우, 회원가입을 탈퇴하거나 회원에서 제명된 경우
					</p>
					<p>– 대금결제 및 재화 등의 공급에 관한 기록 : 5년</p>
					<p>– 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</p>

					<h5>4. 결제 서비스 이용시 개인정보 수집 정보</h5>
					<ul>
						<li>
							정산대금 지급시 : [필수] 예금주명, 계좌번호,은행명<br />
							[선택] 계좌사본,신분증 사본(마스킹)
						</li>
						<li>
							원천징수처리, 지급명세서 제출, 개인사업자 사업소득 신고, 휴.폐업자
							소득신고 및 처리결과 회신 [필수] 이름,주민등록번호, 주소, 이메일
						</li>
						<li>
							유료 결제 서비스 이용과정시
							<p>-신용카드결제 시 : 카드사명, 카드번호(일부) 등</p>
						</li>
					</ul>

					<h5>5. 개인정보의 파기절차 및 방법</h5>
					<p>
						㈜포체인스는 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
						지체 없이 파기하며, 파기절차 및 방법은 다음과 같습니다.
					</p>
					<ul>
						<li>
							파기절차<br />
							회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후
							별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타
							관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정
							기간 저장된 후 파기됩니다. 별도 DB로 옮겨진 개인정보는 법률에 의한
							경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.
						</li>
						<li>
							파기방법<br />
							전자적인 형태로 저장 된 개인정보는 활용할 수 없는 기술적 방법을
							사용하여 삭제합니다.
						</li>
					</ul>

					<h5>6. 개인정보 제공</h5>
					<p>
						㈜포체인스는 회원의 개인정보를 외부에 제공하지 않습니다. 다만,
						아래의 경우에는 예외로 합니다.
					</p>
					<p>– 회원이 사전에 동의한 경우</p>
					<p>
						– 법령의 규정에 따르거나, 수사 목적으로 법령에 정해진 절차와 방법에
						따라 수사기관의 요구가 있는 경우
					</p>

					<h5>7. 수집한 개인정보의 위탁</h5>
					<p>
						㈜포체인스는 현재 회원 서비스와 관련하여 전문업체에 위탁하여
						운영하고 있지 않습니다. 단, 향후 회원 서비스를 위해 개인정보를
						전문업체에 위탁하여 운영할 경우 사전에 관련 사항을 고지하고 동의에
						따라 이행 하도록 하겠습니다.
					</p>

					<h5>8. 이용자 및 법정대리인의 권리와 그 행사방법</h5>
					<p>
						개인정보보호와 관련한 사항을 개인정보보호책임자에게 서면, 전화,
						이메일로 연락하시면 즉시 조치하겠습니다. 회원이 개인정보의 오류에
						대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 개인정보를 이용
						또는 제공하 지 않습니다. ㈜포체인스는 회원의 요청으로 해지 또는
						삭제된 개인정보를 “회사가 수집하는 개인정보의 보유 및 이용기간”에
						명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록
						처리하고 있습니다.
					</p>
					<h5>
						9. 개인정보 자동수집 장치의 설치, 운영 및 거부에 관한 사항 안내
					</h5>
					<p>
						회사는 회원의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을
						운용할 수 있습니다. 쿠키란 서비스 이용과 정에서 귀하의 단말기로
						전송되는 정보파일이며 회원이 소유한 컴퓨터 장치에 저장 됩니다.
						회사는 쿠키를 다음과 같은 목적으로 사용합니다.
					</p>
					<ul>
						<p class="padding_p">가. 쿠키 등 사용 목적</p>
						<li>
							회원과 비회원의 서비스 접속(빈도, 시간) 등을 분석하여 회원에
							적합한 부가서비스를 제공
						</li>
						<li>
							회원은 쿠키 설치를 선택할 수 있으며, 회원이 서비스 이용에 사용하는
							브라우저의 설정에서 쿠키 설치의 허용 또는 거부할 수 있습니다.
						</li>
					</ul>

					<ul class="padding_p">
						<p>나. 쿠키 설정 거부 방법</p>
						<li>
							쿠키 설정거부는 브라우저의 옵션에서 모든 쿠키를 허용, 쿠키를
							저장할 때마다 확인, 모든 쿠키의 저장을 거부하는 것으로 설정할 수
							있습니다.
						</li>
						<li>
							설정방법 예(인터넷 익스플로러의 경우) : 웹 브라우저 도구 > 인터넷
							옵션 > 개인정보
						</li>
						<li>
							단, 회원이 쿠키 설치를 거부하였을 경우 부가서비스 제공이 제한될 수
							있습니다.
						</li>
					</ul>

					<h5>10. 개인정보보호 책임자(CPO,DPO) 및 담당부서</h5>
					<p>
						㈜포체인스는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을
						처리하기 위하여 개인정보보호책임자를 지정하고 있습니다.
					</p>
					<p>
						책임자 : <br />
						담당부서 : <br />
						전화 : <br />
						이메일 : <br />
					</p>
					<p>
						회원은 회사의 서비스 이용과정에서 발생하는 모든 개인정보보호 관련
						민원을 개인정보보호책임자에게 신고하실 수 있으며, 신고 된 내용에
						대해 성실히 답변 하겠습니다. 기타 개인정보침해에 대한 신고나 상담이
						필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
					</p>
					<p>개인분쟁조정위원회<a href="#">(www.1336.or.kr/1336)</a></p>
					<p>
						정보보호마크인증위원회<a href="#"
							>(www.eprivacy.or.kr/02-580-0533~4)</a
						>
					</p>
					<p>
						대검찰청 사이버수사과<a href="#"
							>(http://www.spo.go.kr/spo/major/disclosure2/disclosure01.jsp /
							02-3480-3600)</a
						>
					</p>
					<p>
						경찰청 사이버안전국<a href="#"
							>(http://cyberbureau.police.go.kr/02-392-0330)</a
						>
					</p>

					<h5>11. 고지의 의무</h5>
					<p>
						㈜포체인스는 개인정보 처리방침 내용 추가, 삭제 및 수정이 있을 시에는
						개정 최소 7일전부터 홈페이지의 ‘공지사항’을 통해 고지할 것입니다.
						다만, 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의
						중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다.
					</p>
					<p>공고일자: 시행일자:</p>
				</section>
			</div>
			<section class="content6">
				<h3 class="color_red">부칙</h3>
				<h3 class="font">본 약관은 회원가입일 부터 적용됩니다.</h3>
			</section>
		</div>
	</div>
	<div id="Back_color" v-else-if="computedValue === `en`">
		<header>
			<h1>FOUR-CHAINS Privacy Policy</h1>
		</header>
		<div id="Container_box">
			<div id="container">
				<section class="content1">
					<h5>1. Scope of Personal Information Collection</h5>
					<p>
						Fourchains Inc. (hereinafter referred to as "the Company" or
						"Fourchains") allows access to services after membership
						registration. Additionally, for personal members, service access is
						only available for information related to the service. If a
						conversion to a corporate membership is required, services can be
						accessed upon providing the information requested by Fourchains Inc.
					</p>
					<ul>
						<li>
							Personal Information Collected During Membership Registration
						</li>
						<p>
							– Required fields for personal members: ID, password, phone number
							(either mobile or home), email address
						</p>
						<p>
							– Optional fields for personal members: Name, date of birth,
							company, address
						</p>
					</ul>

					<h5>2. Purpose of Collection and Use of Personal Information</h5>
					<ul>
						<li>
							Fourchains Inc. collects personal information for the following
							purposes:.
						</li>
						<p>
							– Name, ID, Password: Used for the identification process of the
							member (real-name verification).
						</p>
						<p>
							– Email address, contact number (mobile or home): Used as a
							communication channel for notices, updates, customer support, and
							other service improvements.
						</p>
						<li>
							Fourchains Inc. does not collect personal information that could
							infringe on the member's human rights (such as race, ethnicity,
							ideology, religion, place of origin, political opinions, criminal
							records, health status, sexual activity, etc.), except for the
							information stated above.
						</li>
					</ul>

					<h5>3. Retention and Use Period of Personal Information</h5>
					<p>
						Member personal information will be destroyed once the purpose of
						collection or the purpose for which it was provided is achieved.
						However, if retention is necessary for a specific period under
						relevant laws (such as the Commercial Act), personal information
						will be kept for the required duration to verify transaction-related
						rights and obligations.
					</p>
					<p>
						– Membership information: Retained until the member cancels their
						registration or is expelled from the membership.
					</p>
					<p>– Payment and transaction records: 5 years.</p>
					<p>– Consumer complaints or dispute resolution records: 3 years.</p>

					<h5>4. Personal Information Collected for Payment Services</h5>
					<ul>
						<li>
							Settlement Payment: <br />
							Required: Account holder name, account number, bank name Optional:
							Copy of the account, copy of ID (masked)
						</li>
						<li>
							For Tax Withholding, Payment Statement Submission, Individual
							Business Income Reporting, and Response for Closed or Suspended
							Business: Required: Name, resident registration number, address,
							email
						</li>
						<li>
							During Paid Service Usage:
							<p>
								-For credit card payments: Card issuer name, partial card
								number, etc.
							</p>
						</li>
					</ul>

					<h5>5. Destruction Procedure and Method of Personal Information</h5>
					<p>
						Fourchains Inc. will immediately destroy personal information once
						the purpose of collection and use has been fulfilled. The procedure
						and method for destruction are as follows.
					</p>
					<ul>
						<li>
							Destruction Procedure<br />
							Information entered for membership registration and other purposes
							will be transferred to a separate database (or, in the case of
							paper, a separate file) after the purpose is fulfilled. It will be
							stored for a certain period based on internal policies and other
							relevant laws (refer to retention and usage periods) and then
							destroyed. Personal information moved to a separate database will
							not be used for any purposes other than retention, unless required
							by law.
						</li>
						<li>
							Destruction Method<br />
							Personal information stored electronically will be deleted using
							technical methods that render it irrecoverable.
						</li>
					</ul>

					<h5>6. Provision of Personal Information</h5>
					<p>
						Fourchains Inc. does not provide personal information to external
						parties. However, exceptions are made in the following cases.
					</p>
					<p>– When the member has given prior consent</p>
					<p>
						– In accordance with legal provisions, or when required by law
						enforcement agencies following legal procedures and methods for
						investigative purposes.
					</p>

					<h5>7. Outsourcing of Collected Personal Information</h5>
					<p>
						Fourchains Inc. does not currently outsource the operation of
						membership services to specialized companies. However, if personal
						information is outsourced to a specialized company for membership
						services in the future, the company will notify members in advance
						and proceed with obtaining their consent.
					</p>

					<h5>
						8. Rights of Users and Legal Representatives and How to Exercise
						Them
					</h5>
					<p>
						If you have any inquiries regarding personal information protection,
						you can contact the Personal Information Protection Officer in
						writing, by phone, or via email, and immediate action will be taken.
						If a member requests correction of personal information errors, the
						information will not be used or provided until the correction is
						completed. Fourchains Inc. processes personal information that has
						been terminated or deleted upon the member's request in accordance
						with the "Retention and Use Period of Personal Information Collected
						by the Company" and ensures that it cannot be accessed or used for
						any other purposes.
					</p>
					<h5>
						9. Installation, Operation, and Refusal of Automatic Personal
						Information Collection Devices
					</h5>
					<p>
						Fourchains Inc. may use devices such as cookies to store and
						retrieve member information regularly. A cookie is an information
						file sent to your terminal during service use and stored on your
						computer device. The company uses cookies for the following
						purposes.
					</p>
					<ul>
						<p class="padding_p">
							a. Purpose of Using Cookies and Similar Devices
						</p>
						<li>
							To analyze the service access patterns (frequency, time) of both
							members and non-members, and provide additional services suitable
							for the members.
						</li>
						<li>
							Members can choose to install cookies, and can allow or deny
							cookie installation through the settings of the browser they use
							for service access.
						</li>
					</ul>

					<ul class="padding_p">
						<p>b. How to Refuse Cookie Installation</p>
						<li>
							Cookie installation can be refused by configuring the browser
							settings to allow all cookies, confirm whenever cookies are saved,
							or refuse all cookie storage.
						</li>
						<li>
							For example, in Internet Explorer: Tools > Internet Options >
							Privacy
						</li>
						<li>
							However, if members refuse to install cookies, additional services
							may be limited.
						</li>
					</ul>

					<h5>
						10. Personal Information Protection Officer (CPO, DPO) and
						Responsible Department
					</h5>
					<p>
						Fourchains Inc. designates a Personal Information Protection Officer
						to protect members' personal information and handle complaints
						related to personal data.
					</p>
					<p>
						Officer : <br />
						Responsible Department : <br />
						Phone : <br />
						Email : <br />
					</p>
					<p>
						Members can report any personal data protection-related issues
						arising during the use of the company's services to the Personal
						Information Protection Officer, and we will provide a sincere
						response to the reported issues. For other personal information
						infringement reports or consultations, please contact the following
						organizations.
					</p>
					<p>
						Personal Dispute Mediation Committee<a href="#"
							>(www.1336.or.kr/1336)</a
						>
					</p>
					<p>
						Privacy Mark Certification Committee<a href="#"
							>(www.eprivacy.or.kr/02-580-0533~4)</a
						>
					</p>
					<p>
						Supreme Prosecutor's Cyber Crime Division<a href="#"
							>(http://www.spo.go.kr/spo/major/disclosure2/disclosure01.jsp /
							02-3480-3600)</a
						>
					</p>
					<p>
						National Police Agency Cyber Safety Bureau<a href="#"
							>(http://cyberbureau.police.go.kr/02-392-0330)</a
						>
					</p>

					<h5>11. Obligation to Notify</h5>
					<p>
						Fourchains Inc. will notify users of any changes, additions,
						deletions, or modifications to the Privacy Policy at least 7 days
						before the revision through the ‘Notice’ section on the website.
						However, in cases where there are significant changes to users'
						rights, such as collection and utilization of personal information,
						or third-party provision, we will notify users at least 30 days in
						advance.
					</p>
					<p>Notice Date : <br />Effective Date :</p>
				</section>
			</div>
			<section class="content6">
				<h3 class="color_red">Supplementary Provisions</h3>
				<h3 class="font">
					These terms and conditions apply from the date of membership
					registration.
				</h3>
			</section>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		computedValue() {
			return this.$store.state.language;
		},
	},
};
</script>

<style scoped>
* {
	margin: 0 auto;
	padding: 0;
	font-family: 'Noto Sans KR', sans-serif;
}
#Back_color {
	background-color: #ffffff;
	height: 969px;
}
header {
	text-align: center;
	padding-top: 100px;
}
a {
	color: blue;
}
p {
	line-height: 1.4rem;
	font-size: 14px;
	padding-top: 10px;
}

ul {
	padding-top: 10px;
	font-size: 14px;
}

li {
	list-style: decimal inside;
	font-size: 14px;
	padding: 4px 0 4px 0;
	line-height: 1.4rem;
}

h5 {
	font-size: 15px;
	color: blue;
	padding: 15px 0 15px 0;
	font-weight: 500;
}
h1 {
	font-weight: 500;
	font-size: 2em;
}

#container {
	width: 60%;
	height: 600px;
	margin-top: 40px;
	margin-bottom: 25px;
	border-top: solid 1px black;
	border-bottom: solid 1px black;
	background-color: #ffff;
	overflow-x: hidden;
	overflow-y: auto;
}
.content1 {
	width: 80%;
	padding-top: 20px;
	padding-bottom: 20px;
}
.padding_p {
	padding-top: 15px;
}

.color_red {
	color: red;
}
.content6 h3 {
	font-size: 18px;
	text-align: center;
	padding-top: 10px;
	padding-bottom: 0;
}
.font {
	font-weight: 500;
}
</style>
